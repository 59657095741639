<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0 "
        >
          <auth-illustration></auth-illustration>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <!-- brand logo -->
                  <app-logo></app-logo>
                <!--/ brand logo -->
                </v-card-text>

                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Welcome to  {{ appName }} ! 👋🏻
                  </p>
                  <p class="mb-2">
                    Please sign-in to your account and start the adventure
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-alert
                      v-if="requestError"
                      color="error"
                      text
                      dark
                      class="my-4"
                    >
                      {{ requestError }}
                    </v-alert>

                    <flash-message></flash-message>

                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <!-- <v-checkbox
                        hide-details
                        label="Remember Me"
                        class="mt-0"
                      >
                      </v-checkbox> -->

                      <!-- forget link -->
                      <router-link
                        :to="{name:'auth-forgot-password'}"
                        class="ms-3"
                      >
                        Forgot Password?
                      </router-link>
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="loading"
                    >
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    New on our platform?
                  </p>
                  <router-link :to="{name:'auth-register'}">
                    Create an account
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import AppLogo from '@/views/components/app/AppLogo.vue'
import AuthIllustration from '@/views/components/auth/AuthIllustration.vue'

export default {
  metaInfo: {
    title: 'Login ',
  },
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    const loading = ref(false)
    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const requestError = ref('')
    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return
      loading.value = true

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      axios
        .post('auth/login?v=1', { email: email.value, password: password.value })
        .then(response => {
          const { accessToken } = response.data

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', accessToken)

          return response
        })
        .then(res => {
          vm.$cookie.set('dvbzsslg', 1, { domain: process.env.VUE_APP_DOMAIN })
          vm.$cookie.set('attkn', res.data.accessToken, { domain: process.env.VUE_APP_DOMAIN })

          axios.get('auth/user').then(response => {
            const user = response.data

            const { ability: userAbility } = user

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)

            // Set user's ability in localStorage for Access Control
            localStorage.setItem('userAbility', JSON.stringify(userAbility))

            // We will store `userAbility` in localStorage separate from userData
            // Hence, we are just removing it from user object
            delete user.ability

            // Set user's data in localStorage for UI/Other purpose
            localStorage.setItem('userData', JSON.stringify(user))

            // Set user's data in store auth
            vm.$store.dispatch('auth/setUserData', user)
            vm.$store.dispatch('tracking/logActivity', { activityName: 'Login' })

            if (vm.$route.query.route) {
              router.push(vm.$route.query.route)
            } else {
              // On success redirect to home
              router.push('/')
            }
          })
        })
        .catch(error => {
          console.log(error)
          loading.value = false

          // TODO: Next Update - Show notification
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error.response)
          requestError.value = error.response.data.message
        })
    }

    // temporary
    const twitterAuthURL = process.env.VUE_APP_TWITTER_AUTH_URL

    return {
      loading,
      handleFormSubmit,

      isPasswordVisible,
      email,
      password,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
      requestError,
      twitterAuthURL,
    }
  },
  components: {
    AppLogo,
    AuthIllustration,
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
